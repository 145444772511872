<template>
  <div>
    <load-profile></load-profile>
    <section class="invoice-preview-wrapper">
      <b-row v-if="data.id" class="invoice-preview">
        <b-col cols="12" md="8" xl="9" class="pr-md-0">
          <b-card no-body class="invoice-preview-card">
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <div>
                  <div class="logo-wrapper">
                    <h3 class="text-primary invoice-logo ml-0">
                      {{ $t("breadcrumb.purchaseInvoice") }}
                    </h3>
                  </div>
                </div>

                <div class="mt-md-0 mt-2">
                  <h4 class="invoice-title">
                    <span class="invoice-number">#{{ data.no }}</span>
                  </h4>
                </div>
              </div>
            </b-card-body>

            <hr class="invoice-spacing" />

            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <b-col cols="12" xl="6" class="p-0">
                  <h6>{{ $t("field.vendor") }}:</h6>
                  <h6 class="mb-25">
                    {{ data.payToName }}
                  </h6>
                  <p class="card-text mb-25">
                    {{ data.payToVendorNo }}
                  </p>
                  <p class="card-text mb-25">
                    {{ data.payToAddress }}
                  </p>
                </b-col>

                <b-col
                  xl="6"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                >
                  <div>
                    <h6>{{ $t("field.information") }}:</h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            {{ $t("field.vendorInvoiceNo") }} :
                          </td>
                          <td>
                            <span class="font-weight-bold">
                              {{ data.vendorInvoiceNo }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">{{ $t("field.status") }} :</td>
                          <td>
                            <span class="font-weight-bold" :class="data.status">
                              {{ data.status }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">{{ $t("field.postingDate") }} :</td>
                          <td>
                            <span class="font-weight-bold">
                              {{ data.postingDate | formatDate("LL") }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            {{ $t("field.paymentMethod") }} :
                          </td>
                          <td>
                            <span class="font-weight-bold">
                              {{ data.paymentMethod }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <div class="order-summary">
              <n-table
                class="px-2 order-summary-table"
                ref="table"
                :fields="fields"
                :items="items"
              >
                <template #cell(quantity)="data">
                  {{ data.item.quantity }} {{ data.item.unitOfMeasureCode }}
                </template>
              </n-table>

              <b-card-body class="invoice-padding">
                <b-row>
                  <b-col cols="12" class="d-flex justify-content-end">
                    <div class="invoice-total-wrapper">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ $t("field.total") }}:
                        </p>
                        <p class="invoice-total-amount">
                          {{ data.amount | currency }}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ $t("field.vat") }}:
                        </p>
                        <p class="invoice-total-amount">
                          {{ data.totalTaxAmount | currency }}
                        </p>
                      </div>
                      <hr class="my-50" />
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ $t("field.totalWithVat") }}:
                        </p>
                        <p class="invoice-total-amount text-primary">
                          {{ data.amountIncludingVat | currency }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BCardBody, BBadge, BTr, BTh } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import NButtonLoading from "@/components/NButtonLoading";
import NTable from "@/components/NTable";
import tableFields from "./viewTableFields";
import ConfirmModal from "@/components/ConfirmModal.vue";

const PurchaseInvoiceRepository = Repository.get("purchaseInvoice");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BBadge,
    BTr,
    BTh,

    NTable,
    ConfirmModal,
    NButtonLoading,
  },
  computed: {
    user: function () {
      return this.$store.state.profile.username
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem("userData"));
    },
  },
  data() {
    return {
      loading: false,
      data: {},
      items: [],
    };
  },
  created() {
    this.show();
  },
  methods: {
    show() {
      this.loading = true;
      PurchaseInvoiceRepository.show(this.$route.params.id)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data = { ...data };
            this.items = [...data.lines];
          }
        })
        .catch()
        .then(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = [...tableFields];

    const resource = "agent-purchase-invoice";
    const route = "purchase-invoice";

    return {
      fields,
      resource,
      route,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>